import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'
import { Store } from '@ngrx/store'
// import * as fromDashboards from '@app/dashboards/store'
import moment from 'moment'

@Component({
    selector: 'app-dashboard-filter',
    templateUrl: './dashboard-filter.component.html',
    styleUrls: ['./dashboard-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardFilterComponent implements OnInit, OnDestroy {

    @Output()
    setFilter = new EventEmitter<any>()
    @Input()
    filters
    @Input()
    defaultDate
    @Input()
    currentUser
    @Input()
    loading
    data = {}
    rangeTypes = [
        // { key: 'customDates', name: 'Custom dates' },
        { key: 'today', name: 'Today' },
        { key: 'yesterday', name: 'Yesterday' },
        { key: 'thisWeek', name: 'This week' },
        { key: 'lastWeek', name: 'Last week' },
        // { key: 'thisWeekToDate', name: 'This week to date' },
        { key: 'thisMonth', name: 'This month' },
        { key: 'lastMonth', name: 'Last month' },
        // { key: 'thisMonthToDate', name: 'This month to date' },
        { key: 'thisQuarter', name: 'This quarter' },
        { key: 'lastQuarter', name: 'Last quarter' },
        // { key: 'thisQuarterToDate', name: 'This quarter to date' },
        { key: 'thisYear', name: 'This year' },
        // { key: 'thisYearToDate', name: 'This year to date' },
        // { key: 'thisYearToLastMonth', name: 'This year to last month' },
        // { key: 'lastWeekToDate', name: 'Last week to date' },
        // { key: 'lastMonthToDate', name: 'Last month to date' },
        // { key: 'lastQuarterToDate', name: 'Last quarter to date' },
        { key: 'lastYear', name: 'Last year' },
        // { key: 'lastYearToDate', name: 'Last year to date' },
        { key: 'last7Days', name: 'Last 7 days' },
        { key: 'last30days', name: 'Last 30 days' },
        { key: 'last90days', name: 'Last 90 days' },
      ]
    destroyed$ = new Subject<boolean>();
    selectedRangeType: string

    constructor() { }

    ngOnInit(): void {
        
    }

    selectDateRange(event) {
        this.selectedRangeType = event
    
        const currentDate = moment().format('L')
    
        const firstDayOfMonth = moment().startOf('month').format('L')
        const firstDayOfYear = moment().startOf('year').format('L')
    
        let rangeStartDate
        let rangeEndDate
    
        switch (this.selectedRangeType) {
          case 'customDates':
            event = ''
            rangeStartDate = firstDayOfMonth
            rangeEndDate = moment().endOf('month').format('L')
            break
          case 'today':
            rangeStartDate = currentDate
            rangeEndDate = currentDate
            break
          case 'thisWeek':
            rangeStartDate = moment().weekday(1).format('L')
            rangeEndDate = moment().weekday(7).format('L')
            break
          case 'thisWeekToDate':
            rangeStartDate = moment().weekday(1).format('L')
            rangeEndDate = currentDate
            break
          case 'thisMonth':
            rangeStartDate = firstDayOfMonth
            rangeEndDate = moment().endOf('month').format('L')
            break
          case 'thisMonthToDate':
            rangeStartDate = firstDayOfMonth
            rangeEndDate = currentDate
            break
          case 'thisQuarter':
            rangeStartDate = moment().startOf('quarter').format('L')
            rangeEndDate = moment().endOf('quarter').format('L')
            break
          case 'thisQuarterToDate':
            rangeStartDate = moment().startOf('quarter').format('L')
            rangeEndDate = currentDate
            break
          case 'thisYear':
            rangeStartDate = firstDayOfYear
            rangeEndDate = moment().endOf('year').format('L')
            break
          case 'thisYearToDate':
            rangeStartDate = firstDayOfYear
            rangeEndDate = currentDate
            break
          case 'thisYearToLastMonth':
            rangeStartDate = firstDayOfYear
            rangeEndDate = firstDayOfMonth
            break
          case 'yesterday':
            rangeStartDate = moment().subtract(1, 'days').format('L')
            rangeEndDate = rangeStartDate
            break
          case 'lastWeek':
            rangeStartDate = moment().subtract(1, 'week').weekday(1).format('L')
            rangeEndDate = moment().subtract(1, 'week').weekday(7).format('L')
            break
          case 'lastWeekToDate':
            rangeStartDate = moment().subtract(1, 'week').weekday(1).format('L')
            rangeEndDate = currentDate
            break
          case 'lastMonth':
            rangeStartDate = moment().subtract(1, 'month').startOf('month').format('L')
            rangeEndDate = moment().subtract(1, 'month').endOf('month').format('L')
            break
          case 'lastMonthToDate':
            rangeStartDate = moment().subtract(1, 'month').startOf('month').format('L')
            rangeEndDate = currentDate
            break
          case 'lastQuarter':
            rangeStartDate = moment().subtract(1, 'quarter').startOf('quarter').format('L')
            rangeEndDate = moment().subtract(1, 'quarter').endOf('quarter').format('L')
            break
          case 'lastQuarterToDate':
            rangeStartDate = moment().subtract(1, 'quarter').startOf('quarter').format('L')
            rangeEndDate = currentDate
            break
          case 'lastYear':
            rangeStartDate = moment().subtract(1, 'year').startOf('year').format('L')
            rangeEndDate = moment().subtract(1, 'year').endOf('year').format('L')
            break
          case 'lastYearToDate':
            rangeStartDate = moment().subtract(1, 'year').startOf('year').format('L')
            rangeEndDate = currentDate
            break
          case 'last7Days':
            rangeStartDate = moment().subtract(6, 'days').format('L')
            rangeEndDate = currentDate
            break
          case 'last30days':
            rangeStartDate = moment().subtract(30, 'days').format('L')
            rangeEndDate = currentDate
            break
          case 'last90days':
            rangeStartDate = moment().subtract(90, 'days').format('L')
            rangeEndDate = currentDate
            break
        }

        this.setFilter.emit({
            'dataRange': event,
            'startDate': moment.utc(rangeStartDate, 'MM/DD/YYYY').utc().startOf('day').toISOString(),
            'endDate': moment.utc(rangeEndDate, 'MM/DD/YYYY').utc().endOf('day').toISOString()
        })
    }

    resetFilter () {
      const startOf = moment().startOf('month').format('L')
      const endOf = moment().endOf('month').format('L')
      this.selectedRangeType = 'thisMonth'
      this.setFilter.emit({
        'dataRange': 'thisMonth',
        'startDate': moment.utc(startOf, 'MM/DD/YYYY').utc().startOf('day').toISOString(),
        'endDate': moment.utc(endOf, 'MM/DD/YYYY').utc().endOf('day').toISOString(),
        'equipmentId': null, 
        'equipmentName': null,
        'driverId': null, 
        'driverName': null
    })
    }

    ngOnDestroy() {
        // this.destroyed$.next(true)
        // this.destroyed$.complete()
    }
}
